<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :items="sarprasRehabs"
          :headers="headers"
          :page="page"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :title="titleCard"
          subtitle="Halaman untuk mengajukan rehab sarpras ke dinas"
          add-btn="Ajukan Rehab"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @show-image="showImage"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Pengajuan Rehab': 'Edit Pengajuan Rehab'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-form ref="form">
          <v-row class="py-5 px-5">
            <v-col
              xl="12"
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <div>
                <FileInput
                  :value="initialFile"
                  label="Foto Sarpras"
                  outlined
                  :prependicon="icons.mdiImageMultipleOutline"
                  dense
                  @input="getFile"
                ></FileInput>
              </div>
            </v-col>
            <v-col
              xl="12"
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <div>
                <v-textarea
                  v-model="sarprasRehab.reason"
                  label="Alasan"
                  dense
                  value
                  outlined
                ></v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Pengajuan"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    ></ConfirmDialog>
    <ImageOverlay
      :visible="modalDialogImage"
      :width="800"
      @close="modalDialogImage = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="modalDialogImage = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="sarprasRehab.photo"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import FileInput from '@/views/components/FileInput.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'
import { mdiArrowLeft, mdiImageMultipleOutline } from '@mdi/js'

export default {
  name: 'SarprasRehab',
  components: {
    MainCard,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    DataTablePagination,
    ImageOverlay,
    FileInput,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        mdiImageMultipleOutline,
      },
      dialog: '',
      search: '',
      width: 1000,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      modalDialog: false,
      modalDialogImage: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      validation: {
        required,
        integerValidator,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Alasan', value: 'reason' },
        { text: 'Foto', value: 'photo' },
        { text: 'Status', value: 'statusArray' },
        { text: 'Actions', value: 'actions' },
      ],
      sarprasStatus: {
        pending: 'Request',
        approved: 'Approved',
        rejected: 'Reject',
      },
      sarprasRehabs: [],
      formValid: false,
      date_out: false,
      sarprasRehab: {
        sarpras_uuid: '',
        photo: '',
        reason: '',
        status: '',
      },
      service: 'sarprasrehab',
      titleCard: '',
      school_uuid: '',
      students: [],
      formData: [],
      filterQuery: {
        search: '',
      },
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.sarprasRehab.photo === 'string') {
          return new File([this.sarprasRehab.photo], this.sarprasRehab.photo, { type: 'text/plain' })
        }

        return this.sarprasRehab.photo
      },
      set() {
        return this.sarprasRehab.photo
      },
    },
  },
  watch: {
    page: {
      handler() {
        this.listRehab(this.$route.params.uuid, this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.getSarpras(this.$route.params.uuid)
    this.listRehab(this.$route.params.uuid)
  },
  methods: {
    resetForm() {
      this.$refs.form.reset()
    },
    getFile(data) {
      this.sarprasRehab.photo = data
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        this.school_uuid = el.uuid
        this.sarprasRehab.school_uuid = this.school_uuid
      })

      return true
    },
    async getSarpras() {
      await this.$services.ApiServices.get('sarpras', this.$route.params.uuid).then(
        ({ data }) => {
          this.titleCard = `Rehab untuk ${data.data.name}`
        },
        err => console.error(err),
      )
    },
    async listRehab(uuid, params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        sarpras_uuid: uuid,
      }).then(
        ({ data }) => {
          this.sarprasRehabs = data.data.map((sarprasRehab, index) => ({
            ...sarprasRehab,
            statusArray: this.sarprasStatus[sarprasRehab.status],
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.$data.sarprasRehab.sarpras_uuid = this.$route.params.uuid
      this.$data.sarprasRehab.status = 'pending'
      Object.entries(this.$data.sarprasRehab).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listRehab(this.$route.params.uuid, this.filterQuery)
      this.resetForm()
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.sarprasRehab = data.data
      })
    },
    async update() {
      console.log('ok update')
      this.isLoadingButton = true
      this.formData = new FormData()
      Object.entries(this.$data.sarprasRehab).forEach(([key, item]) => {
        this.formData.append(key, item)
      })
      const oldPhoto = this.$data.sarprasRehab.photo
      if (typeof oldPhoto && typeof this.formData.get('photo') === 'string') {
        this.formData.delete('photo')
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.sarprasRehab.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.listRehab(this.$route.params.uuid, this.filterQuery)
      this.resetForm()
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.sarprasRehab.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('sarprasrehab', this.sarprasRehab.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => console.error(err),
      )
      this.listRehab(this.$route.params.uuid, this.filterQuery)
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      await this.listRehab(this.$route.params.uuid, this.filterQuery)
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get('sarprasrehab', uuid).then(
        ({ data }) => {
          this.sarprasRehab = data.data
          this.sarprasRehab.photo = data.data.photo
        },
        err => console.error(err),
      )
      this.modalDialogImage = true
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
